.connectedContainer {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  text-align: left;
  gap: var(--space-1);
}

.popoverContainer {
  padding: var(--space-2);
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
  border: 1px solid var(--color-border-light);
}

.addressName {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.rowContainer {
  align-self: stretch;
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 4px var(--space-2);
  margin-top: -2px;
}

.row:last-of-type {
  border-bottom: 1px solid var(--color-border-light);
}

.pairingDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
}

@media (max-width: 599.95px) {
  .buttonContainer {
    transform: scale(0.8);
  }

  .notConnected,
  .pairingDetails {
    display: none;
  }
}
