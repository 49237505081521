.pendingButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding-left: 0px;
  margin-right: var(--space-2);
}

.pendingButton {
  width: 52px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
}

.pendingButton img {
  height: 24px;
  width: 20px;
}

.missingSignatures {
  height: 32px;
  background-color: var(--color-info-light);
}

[data-theme='dark'] .missingSignatures {
  background-color: var(--color-info-dark);
}

.queued {
  background-color: var(--color-background-main);
}

[data-theme='dark'] .queued {
  background-color: var(--color-secondary-dark);
}
