.container {
  border: 1px solid var(--color-border-light);
}

.method {
  padding: var(--space-2) var(--space-2) var(--space-1) var(--space-2);
}

.label {
  color: var(--color-text-secondary);
}

.radioLabel {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: calc(var(--space-1) / 2);
}
