.outline {
  border: 1px solid var(--color-border-light);
  border-radius: 6px;
}

.error {
  border-color: var(--color-error-main);
}

.error :global(.MuiFormLabel-root) {
  color: var(--color-error-main);
}

.label {
  background-color: var(--color-background-paper);
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -6px;
}

.inputs {
  display: inline-flex;
  align-items: center;
}

.amount {
  min-width: 130px;
  flex-grow: 1;
}

.amount :global(.MuiInput-input) {
  padding-left: var(--space-2);
}

.max {
  text-transform: uppercase;
  background-color: var(--color-background-main);
  color: var(--color-text-primary);
  font-size: 12px;
  margin-right: var(--space-1);
  min-height: 50px;
  padding: var(--space-2);
}

.select {
  flex-shrink: 0;
}

.select :global(.MuiSelect-select) {
  margin: var(--space-1);
  display: flex;
  background-color: var(--color-background-main);
  border-radius: 6px;
  padding: var(--space-1) var(--space-5) var(--space-1) var(--space-2) !important;
}

.select :global(.MuiSelect-icon) {
  margin-right: var(--space-2);
}
